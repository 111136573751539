<template>
  <div class="app">
    <AppHeader fixed>
      <i class="menu-toggler" :class="{'opened': menuView}" @click="menuView = !menuView">
        <div class="dashs">
          <span/>
          <span/>
          <span/>
        </div>
      </i>
      <b-link class="navbar-brand" to="/">
        <img
          class="navbar-brand-full"
          src="/img/brand/logo.svg"
          width="130"
          height="30"
          alt="Kovi Rental"
        >
        <img
          class="navbar-brand-minimized"
          src="/img/brand/logo-square.svg"
          width="30"
          height="30"
          alt="Kovi Rental"
        >
      </b-link>
      <b-nav-form class="d-md-down-none d-inline flex-fill px-2">
        <header-search/>
      </b-nav-form>
      <user-header
        :version="pkgVersion"
        :env="appEnv"
        :build-url="appBuildUrl"
        :build="appBuild"
        :user-email="userEmail"
      />
    </AppHeader>
    <div class="app-body" :class="{'no-menu': !menuView}">
      <v-side-menu/>
      <main class="main">
        <div class="container-fluid top-space">
          <slot name="navigation"/>
          <slot name="content"/>
          <slot/>
        </div>
      </main>
    </div>

    <modal-inspection-car-schedule/>
    <modal-delivery-car-schedule/>
    <modal-devolution-car-schedule/>
    <modal-delivery-and-devolution-car-schedule/>
  </div>
</template>

<script>
import HeaderSearch from '@components/search/header-search';
import VSideMenu from '@components/v-side-menu';
import UserHeader from '@components/user-header';
import { Header as AppHeader } from '@coreui/vue';
import { debounce } from '@utils/debounce';

const pkgVersion = require('@/package.json').version;
const seedVersion = process.env.SEED_BUILD_ID || null;
const processEnv = process.env.RENTAL_ENV || 'local';

import ModalDeliveryCarSchedule from '@components/drivers/profile/modals/DeliveryCarSchedule';
import ModalDevolutionCarSchedule from '@components/drivers/profile/modals/DevolutionCarSchedule';
import ModalDeliveryAndDevolutionCarSchedule
  from '@components/drivers/profile/modals/DeliveryAndDevolutionCarSchedule';
import ModalInspectionCarSchedule
  from '@components/drivers/profile/modals/InspectionCarSchedule';

export default {
  name: 'DefaultContainer',
  components: {
    HeaderSearch,
    VSideMenu,
    AppHeader,
    UserHeader,
    ModalDeliveryCarSchedule,
    ModalDevolutionCarSchedule,
    ModalDeliveryAndDevolutionCarSchedule,
    ModalInspectionCarSchedule
  },
  data() {
    return {
      appEnv: null,
      appBuild: null,
      appBuildUrl: null,
      pkgVersion: '1234',
      menuView: true,
      userEmail: null,
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label,
      );
    },
  },
  beforeMount() {
    this.menuView = window.innerWidth < 992 ? false : true;
    this.userEmail = this.$store.getters['user/attributes'].email;
    this.appEnv = processEnv;
    this.appBuild = seedVersion;
    this.appBuildUrl = `https://console.seed.run/kovi/rental-frontend/stages/${processEnv}/builds/${seedVersion}`;
    this.pkgVersion = pkgVersion;
  },
  mounted() {
    this.sizePageListener();
  },
  destroyed() {
    this.sizePageListener();
  },
  methods: {
    sizePageListener() {
      let returnResize = debounce(() => {
        this.menuView = window.innerWidth > 992 ? true : false;
      }, 250);
      window.addEventListener('resize', returnResize);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-toggler {
  font-size: 30px;
  padding: 20px 20px 17px;
  cursor: pointer;

  .dashs {
    width: 20px;
    height: 16px;
    position: relative;
    margin: 0 0 0 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #000;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      transform-origin: left center;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 6px;
      }

      &:nth-child(3) {
        top: 12px;
      }
    }
  }

  &.opened {
    .dashs {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0;
          left: 1px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
          left: 1px;
        }
      }
    }
  }
}

.navbar-brand {
  width: 185px;
  justify-content: start;
  padding: 13px 15px 13px 0;
  display: block;
  text-align: left;
}

.app-body {
  .main {
    margin-left: 230px;
  }

  #sidebar-menu {
    z-index: 850;
    left: 0;
    transition: left 0.3s ease;
  }

  &.no-menu {
    #sidebar-menu {
      left: -230px;
    }

    .main {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .app-body {
    .main {
      margin-left: 0;
      z-index: 840;
    }
  }
}
</style>


