var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
    _c("div", { staticClass: "align-middle" }, [
      _c("i", { class: _vm.option.icon, attrs: { "aria-hidden": "true" } }),
    ]),
    _c("div", { staticClass: "px-2 flex-fill" }, [
      _c("div", { staticClass: "option__desc" }, [
        _c("p", { staticClass: "m-0" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.option.title)),
          ]),
          _vm.option.hasBooking
            ? _c("span", [
                _vm._v(
                  "  - " +
                    _vm._s(_vm.$t("component.headerSearch.isActiveBooking")) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("br"),
          _c("span", { staticClass: "font-weight-light" }, [
            _vm._v(_vm._s(_vm.option.description)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }