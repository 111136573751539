<template>
  <multiselect
    :close-on-select="true"
    :group-select="false"
    :internal-search="false"
    :loading="isLoading"
    :max-height="maxResultHeight"
    :clear-on-select="true"
    :multiple="false"
    :option-height="60"
    :options="options"
    :placeholder="$t('component.headerSearch.placeholder')"
    :reset-after="false"
    :select-label="$t('component.headerSearch.selectLabel')"
    :show-no-results="false"
    group-label="category"
    group-values="data"
    track-by="title"
    class="header-search"
    v-on="$listeners"
  >
    <template
      slot="singleLabel"
      slot-scope="props"
    >
      <div class="option__image">
        <i
          :class="props.option.icon"
          aria-hidden="true"
        />
      </div>
      <span class="option__desc">
        <span class="option__title">{{ props.option.title }}</span>
      </span>
    </template>
    <template
      v-if="!option.$isLabel"
      slot="option"
      slot-scope="{ option, search, index }"
    >
      <header-search-item
        :option="option"
      />
    </template>
    <template
      slot="noResult"
    >
      {{ $t('component.headerSearch.noResult') }}
    </template>
  </multiselect>
</template>
<script>
import HeaderSearchItem from '@components/search/common/searchItem';
import Formatters from '@utils/formatters';

export default {
  name: 'HeaderSearchBar',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMatch: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      default: () => ([]),
    }
  },
  components: {
    HeaderSearchItem,
  },
  computed: {
    maxResultHeight() {
      const windowHeight = window.innerHeight - 100;

      return windowHeight > 0 ? windowHeight : 400;
    },
  },
}
</script>
<style lang="scss">
.header-search {
  .badge{
    position: unset !important;
  }
  .option__desc {
    .font-weight-light {
      font-size: .85rem;
    }
  }
}
</style>
