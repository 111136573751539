import Formatters from '@utils/formatters';
import { VEHICLE_TYPE, DRIVER_TYPE } from '../enums';

export default {
  methods: {
    getDescription(type, props) {
      switch (type) {
        case VEHICLE_TYPE:
          return `${Formatters.toTitleCase(props.brand)} ${Formatters.toTitleCase(Formatters.clearBrandName(props.model))}`;
        case DRIVER_TYPE:
          return `${this.$t('component.headerSearch.document')}: ${Formatters.formatDocument(props.cpf)}
          | ${this.$t('component.headerSearch.phone')}: ${Formatters.formatIntlPhone(props.phone_number)}`;
        default:
          return ``;
      }
    },
  },
};
