<script>
const md5 = require('js-md5');

export default {
  name: 'UserPhoto',
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      picture: '',
      defaultPicture: 'https://ssl.gstatic.com/accounts/ui/avatar_2x.png',
    };
  },
  mounted() {
    this.picture = this.loadPicture(this.email);
  },
  methods: {
    loadPicture(email) {
      return (
        'https://www.gravatar.com/avatar/' +
        md5(email) +
        '?s=192&d=' +
        encodeURI(this.defaultPicture)
      );
    },
  },
};
</script>

<template>
  <img id="profile-img" class="profile-img-card" :src="picture">
</template>
