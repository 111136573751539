import $i18n from 'i18next';

export const availableConductors = [
    {
      value: 'DRIVER',
      text: $i18n.t('drivers.forms.drivers'),
    },
    {
      value: 'CHOFER',
      text: $i18n.t('drivers.forms.chofer'),
    },
    {
      value: 'TOW_TRUCKER',
      text: $i18n.t('drivers.forms.towTrucker'),
    },
    {
      value: 'OTHERS',
      text: $i18n.t('drivers.forms.others'),
    },
  ]
