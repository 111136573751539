const LOCAL_SEARCH_KEY = 'RENTAL_LOCAL_SEARCH';

export default {
  computed: {
    localSearchItems: {
      get() {
        return {
          category: this.$t('component.headerSearch.lastSearch'),
          data: this.getLocalSearchItems(),
        };
      },
      cache: false,
    },
  },
  methods: {
    saveLocalSearchItem(searchObject) {
      const localSearch = this.getLocalSearchItems();

      localSearch.unshift(searchObject);

      localSearch.length = 5;

      const filteredData = localSearch
      .filter(Boolean)
      .filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj['title']).indexOf(obj['title']) === pos
          && arr.map(mapObj => mapObj['description']).indexOf(obj['description']) === pos;
      });

      localStorage.setItem(LOCAL_SEARCH_KEY, JSON.stringify(filteredData));
    },
    getLocalSearchItems() {
      const localSearch = JSON.parse(localStorage.getItem(LOCAL_SEARCH_KEY));

      return Array.isArray(localSearch) ? localSearch : [];
    },
  },
};
