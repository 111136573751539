<template>
  <nav id="sidebar-menu">
    <ul>
      <li v-for="(section, index) in menuItems" :key="index" class="section">
        <template v-if="verifyIgnoredRegion(section) && verifyACLAccess(section)">
          <span>{{ section.name }}</span>
          <ul>
            <li
              v-for="(item, indexSub) in section.subitems"
              :key="indexSub"
              :title="item.name"
              class="items"
              :class="{'active': 'router-link-active'}"
            >
              <template v-if="verifyIgnoredRegion(item) && verifyACLAccess(item)">
                <router-link
                  v-if="item.event === undefined"
                  class="like-link"
                  :to="item.url"
                  :title="item.name"
                >
                  {{ item.name }}
                </router-link>
                <span v-else class="like-link" @click="handleMenuAction(item)">{{ item.name }}</span>
              </template>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
import SideMenuitems from '@components/side-menu'
import { isMexico, isBrazil } from '@utils/helper-region'
import { COUNTRIES } from '@src/enums/regions';

export default {
  name: 'SideMenu',
  data() {
    return {
      menuItems: [],
    };
  },
  mounted() {
    this.menuItems = SideMenuitems.items;
  },
  methods: {
    handleMenuAction(item) {
      if (item.event !== undefined) {
        item.event();
      }
      return false;
    },
    verifyIgnoredRegion(event) {
      return event.ignoreRegions === undefined
        ? true
        : isMexico() && event.ignoreRegions.includes(COUNTRIES.MX)
        ? false
        : !(isBrazil() && event.ignoreRegions.includes(COUNTRIES.BR));
    },
    verifyACLAccess(event) {
      const groups = this.$store.getters['user/groups'];
      // console.log(`Checking access ${e.name}: ${event.acl} (Acccess: ${groups.includes(e.acl)} - GOD: ${groups.includes('ACL:GOD')})`);
      if (event.acl === undefined) {
        return true;
      } else {
        if (groups.includes(event.acl) || groups.includes('ACL:GOD')) return true;
        return false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
ul
  list-style: none
  padding: 0
  margin: 0
.app-body
  #sidebar-menu
    width: 230px
    height: calc(100vh - 55px)
    background: rgba(47, 53, 58, 1)
    padding: 15px 15px 60px
    position: fixed
    overflow-y: scroll
    transition: left .25s ease-in-out
    left: 0
    z-index: 850
    .section
      margin-bottom: 15px
      span
        color: rgba(158, 160, 165,1)
        text-transform: uppercase
        margin-bottom: 8px
        font-size: 12px
        display: block
        padding: 0 10px
      ul
        .items
          a,
          .like-link
            padding: 12px 10px
            color: rgba(255,255,255,1)
            font-size: 13px
            display: block
            text-transform: none
            font-weight: 600
            &:hover
              text-decoration: none
              background: rgba(67, 73, 78, 1)
              cursor: pointer
            &.active
              background: rgba(22, 101, 216, 1)
              border-radius: 4px
</style>
