<script>
  import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';

  export default {
    name: 'UserHeaderRegion',
    components: {
      AppHeaderDropdown,
    },
    data() {
      return {
        regions: [],
        region: '',
        allowedRegions: [
          { value: 'BR:SP:SAO_PAULO', text: 'São Paulo' },
          { value: 'BR:RS:PORTO_ALEGRE', text: 'Porto Alegre' },
          { value: 'MX:DF:CIUDAD_DE_MEXICO', text: 'Ciudad de México' },
        ],
      };
    },
    mounted() {
      this.$root.$on('setRegion', (region) => this.setRegion(region))
      const groups = this.$store.getters['user/groups'];
      this.regions = this.allowedRegions.filter(({ item }) => !groups.includes(item));
      this.region = this.$store.getters['user/region'];

      if (!this.region) this.setRegion(this.regions[0].value);
    },
    methods: {
      setRegion(region) {
        this.$store.dispatch('user/changeRegion', region);
        this.region = this.$store.getters['user/region'];
        location.reload()
      },
      getRegion(region) {
        if (!region) return;
        return this.allowedRegions.find(e => e.value === region).text;
      },
    },
  };
</script>

<template>
  <AppHeaderDropdown right>
    <template slot="header">
      {{ $t('shared.navbar.region') }} <strong>{{ getRegion(region) }}</strong>
    </template>
    <template slot="dropdown">
      <b-dropdown-item
        v-for="item in regions" 
        :key="item.value"
        :title="item.text"
        @click="setRegion(item.value)"
      >
        {{ item.text }}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>
