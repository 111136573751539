<template>
  <div>
    <div v-if="!driverData.cnh_doc" class="alert alert-danger" role="alert">
      {{ $i18n.t('modalBooking.alerts.noDriverLicenseDoc') }}
    </div>
    <div v-else-if="expired" class="alert alert-danger" role="alert">
      {{ $i18n.t('modalBooking.alerts.driverLicenseExpired') }}
    </div>
    <div v-else-if="expiring" class="alert alert-warning" role="alert">
      {{ $i18n.t('modalBooking.alerts.driverLicenseExpiring') }}
    </div>
  </div>
</template>

<script>
import DRIVER_GET from '@graphql/driver/queries/get.gql';
export default {
  props: {
    expiring: {
      type: Boolean,
      default: false,
    },
    expired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      driverData: null,
    }
  },
  apollo: {
    driver: {
      query: DRIVER_GET,
      variables() {
        return {
          id: this.driverId || this.$route.params.id,
        };
      },
      result(data) {
        if (data.data) this.driverData = data.data.driver;
      },
    },
  },
  methods: {

  }
}
</script>
