export const debounce = ($fn, $wait = 250, $now) => {
  let to;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    let later = () => {
      to = null;
      !$now ? $fn.apply(context, args) : ''
    };
    let callNow = $now && !to
    clearTimeout(to)
    to = setTimeout(later, $wait)
    callNow ? $fn.apply(context, args) : ''
  }
}
