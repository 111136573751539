var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header-search-bar", {
    attrs: {
      "is-loading": _vm.isLoading,
      "is-match": _vm.isMatch,
      options: _vm.options,
    },
    on: {
      select: _vm.goToSelected,
      "search-change": _vm.searchByWord,
      close: _vm.resetData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }