var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    _vm._g(
      {
        staticClass: "header-search",
        attrs: {
          "close-on-select": true,
          "group-select": false,
          "internal-search": false,
          loading: _vm.isLoading,
          "max-height": _vm.maxResultHeight,
          "clear-on-select": true,
          multiple: false,
          "option-height": 60,
          options: _vm.options,
          placeholder: _vm.$t("component.headerSearch.placeholder"),
          "reset-after": false,
          "select-label": _vm.$t("component.headerSearch.selectLabel"),
          "show-no-results": false,
          "group-label": "category",
          "group-values": "data",
          "track-by": "title",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "singleLabel",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "option__image" }, [
                    _c("i", {
                      class: props.option.icon,
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]),
                  _c("span", { staticClass: "option__desc" }, [
                    _c("span", { staticClass: "option__title" }, [
                      _vm._v(_vm._s(props.option.title)),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function ({ option, search, index }) {
                return !option.$isLabel
                  ? [_c("header-search-item", { attrs: { option: option } })]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      },
      _vm.$listeners
    ),
    [
      _c("template", { slot: "noResult" }, [
        _vm._v(" " + _vm._s(_vm.$t("component.headerSearch.noResult")) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }