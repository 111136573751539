import i18next from 'i18next';
import { COUNTRIES } from '@src/enums/regions';

export default {
  items: [

    // Dashboards section
    {
      name: i18next.t('menu.dashboards.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.dashboards.overview'),
          url: '/dashboard',
        },
      ]
    },

    // Signup
    {
      name: i18next.t('menu.signup.section'),
      type: 'section',
      subitems: [
        // {
        //   name: i18next.t('menu.signup.overview'),
        //   url: '/drivers/signup/overview',
        // },
        {
          name: i18next.t('menu.signup.docs'),
          url: '/drivers/signup/docs/checking',
        },
        {
          name: i18next.t('menu.signup.invites'),
          url: '/drivers/signup/invite',
        },
        {
          name: i18next.t('menu.signup.payments'),
          url: '/drivers/signup/payments/pending',
        },
        {
          name: i18next.t('menu.signup.schedule'),
          url: '/drivers/signup/delivery',
        },
        {
          name: i18next.t('menu.signup.rejected'),
          url: '/drivers/signup/waiting-list',
        },
      ]
    },

    // Drivers
    {
      name: i18next.t('menu.drivers.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.drivers.overview'),
          url: '/drivers/active'
        },
        // {
        //   name: i18next.t('menu.drivers.trafficTickets'),
        //   url: '/drivers/traffic-tickets',
        // },
        {
          name: i18next.t('menu.drivers.overdue'),
          url: '/drivers/overdue',
        },
        {
          name: i18next.t('menu.drivers.pendingCreditRequests'),
          url: '/credit-requests/pending',
          acl: 'RULE:TRANSACTIONS:CREDIT_UPDATE'
        },
        {
          name: i18next.t('menu.drivers.approvedCreditRequests'),
          url: '/credit-requests/approved',
          acl: 'RULE:TRANSACTIONS:CREDIT_UPDATE'
        },
        {
          name: i18next.t('menu.drivers.manageTed'),
          url: '/ted',
          acl: 'ACL:TED:TO_VIEW'
        },
        {
          name: i18next.t('menu.drivers.failedTed'),
          url: '/ted-failed',
          acl: 'ACL:TED:TO_VIEW',
          ignoreRegions: [COUNTRIES.MX]
        },
        {
          name: i18next.t('menu.drivers.banned'),
          url: '/drivers/banned',
        },
      ]
    },

    // Schedules
    {
      name: i18next.t('menu.schedules.section'),
      type: 'section',
      acl: 'RULE:SCHEDULE:VIEW',
      subitems: [
        {
          name: i18next.t('menu.schedules.delivery'),
          url: '/drivers/scheduled-delivery'
        },
        {
          name: i18next.t('menu.schedules.returns'),
          url: '/drivers/scheduled-devolution'
        },
        {
          name: i18next.t('menu.schedules.inspection'),
          url: '/drivers/scheduled-inspection',
        },
      ]
    },

    // Maintenance
    {
      name: i18next.t('menu.maintenance.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.maintenance.schedulePreventive'),
          url: '/maintenance/schedule',
        },
        {
          name: i18next.t('menu.maintenance.preventive'),
          url: '/maintenance/scheduled',
        },
        // {
        //   name: i18next.t('menu.maintenance.corrective'),
        //   url: '/maintenance/corrective',
        // },
      ]
    },

    // Fleet
    {
      name: i18next.t('menu.fleet.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.fleet.overview'),
          url: '/fleet',
        },
        {
          name: i18next.t('menu.fleet.trafficTickets'),
          url: '/fines',
          ignoreRegions: [COUNTRIES.MX]
        },
        {
          name: i18next.t('menu.fleet.contracts'),
          url: '/contracts',
          ignoreRegions: [COUNTRIES.MX],
          acl: 'ACL:SUPPLIER_CONTRACTS:VIEW',
        },
        {
          name: i18next.t('menu.fleet.providers'),
          url: '/providers'
        }
      ]
    },

    // Safety
    {
      name: i18next.t('menu.safety.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.safety.monitoring'),
          url: '/safety',
        },
        {
          name: i18next.t('menu.safety.paymentLocks'),
          url: '/safety/automatic-lock',
          acl: 'ACL:PAYMENT_LOCK:VIEW',
        },
      ]
    },

    // Insurance
    {
      name: 'Seguros', //i18next.t('menu.safety.section'),
      type: 'section',
      acl: 'ACL:INSURANCE:ADD_CAR',
      subitems: [
        {
          name: 'Veículos', //i18next.t('menu.safety.monitoring'),
          url: '/insurance/fleet',
        },
      ]
    },

    // Configurations
    {
      name: i18next.t('menu.config.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.config.garages'),
          url: '/maintenance/garages',
        },
        {
          name: i18next.t('menu.config.plans'),
          url: '/admin/plans',
          acl: 'ACL:PLANS',
        },
        {
          name: i18next.t('menu.config.places'),
          url: '/admin/places',
        }
      ]
    },

    // CX
    {
      name: i18next.t('menu.cx.section'),
      type: 'section',
      subitems: [
        {
          name: i18next.t('menu.cx.flex'),
          url: '/cx',
        }
      ]
    }
  ]
};
