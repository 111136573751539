var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    { staticClass: "d-md-down-none ml-auto mr-4" },
    [
      _c(
        "b-nav-item",
        {
          staticClass: "support",
          attrs: {
            target: "_blank",
            href: "https://support.kovi.us/?utm_source=rental",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("shared.navbar.support")) + " ")]
      ),
      _c(
        "b-nav-item",
        {
          staticClass: "support",
          attrs: {
            target: "_blank",
            href: "https://erp.kovi.us/?utm_source=rental",
          },
        },
        [_vm._v(" ERP ")]
      ),
      _c("user-header-region"),
      _c(
        "b-nav-item",
        { staticClass: "notifications" },
        [
          _c(
            "AppHeaderDropdown",
            { attrs: { right: "", "no-caret": "" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("i", { staticClass: "fa fa-bell-o" }),
                  _vm.userNotifications > 0
                    ? _c(
                        "b-badge",
                        { attrs: { pill: "", variant: "danger" } },
                        [_vm._v(" " + _vm._s(_vm.userNotifications) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "template",
                { slot: "dropdown" },
                [
                  _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("shared.navbar.zeroNotifications")) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-nav-item",
        { staticClass: "profile" },
        [
          _c(
            "AppHeaderDropdown",
            { attrs: { right: "", "no-caret": "" } },
            [
              _c(
                "template",
                { slot: "header" },
                [_c("user-photo", { attrs: { email: _vm.userEmail } })],
                1
              ),
              _c(
                "template",
                { slot: "dropdown" },
                [
                  _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                    _c("i", { staticClass: "fa fa-sign-out" }),
                    _vm._v(" Sair "),
                  ]),
                  _c("b-dropdown-divider"),
                  _vm.build
                    ? _c("b-dropdown-item", { attrs: { href: _vm.buildUrl } }, [
                        _c("i", { staticClass: "fa fa-rebel" }),
                        _vm._v("Rental "),
                        _c("strong", [_vm._v("v" + _vm._s(_vm.build))]),
                        _vm._v(" (" + _vm._s(_vm.env) + ") "),
                      ])
                    : _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.env === "local" ? "fa-empire" : "fa-rebel",
                        }),
                        _vm._v(" Rental "),
                        _c("strong", [_vm._v(_vm._s(_vm.env))]),
                        _vm._v(" version "),
                      ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }