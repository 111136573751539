<template>
  <b-form-group class="select-wrapper" :label="placeTitle">
    <b-form-select
      v-model="selectedPlace"
      :options="availablePlaces"
      :disabled="!availablePlaces.length || disable"
			data-test="select-places"
		>
      <template slot="first">
        <option
          :value="null"
          disabled
        >
          {{ placeText }}
        </option>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
import LISTBYFILTERS from '@graphql/place/queries/list_by_filter.gql';
export default {
	name: 'SelectPlaces',

	data(){
		return {
			availablePlaces:[],
			selectedPlace: null,
		}
	},

  props: {
    placeTitle: { default: 'Pátio' },
    placeText: { default: 'Selecione...' },
    instance: { default: 'selectPlaces' },
    type: { default: { delivery: true, devolution: true } },
    disable: { default: false }
  },

	apollo: {
		places: {
			query: LISTBYFILTERS,
			variables() {
				return {
					filters: {
						where: {
							status: "ACTIVE",
							type: this.type
						},
					},
				};
			},
			result(data) {
				if (data.data) {
					const result = data.data.places.items;
					this.availablePlaces = result.map(function(item){
						return {
							value: item.id,
							text: `${item.name} (${item.address_street_name},\u00A0${item.address_street_number},\u00A0${item.address_neighborhood},\u00A0${item.address_city},\u00A0${item.address_state})`,
						}
					})
				}
			}
		},
	},

	watch: {
		selectedPlace(value) {
			this.$emit('eventPlaces', { value: value, instance: this.instance })
		}
	}
}
</script>

<style lang="scss" scoped>
.select-wrapper {
	padding:1.25rem 0.25rem 0;

	.custom-select {
		margin-top:-20px;
	}
}
</style>
