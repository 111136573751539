<template>
  <div class="d-flex flex-row align-items-center">
    <div class="align-middle">
      <i
        :class="option.icon"
        aria-hidden="true"
      />
    </div>
    <div class="px-2 flex-fill">
      <div class="option__desc">
        <p class="m-0">
          <span class="font-weight-bold">{{ option.title }}</span>
          <span
            v-if="option.hasBooking"
          >
                &nbsp;- {{ $t('component.headerSearch.isActiveBooking') }}
              </span>
          <br />
          <span class="font-weight-light">{{ option.description }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderSearchItem',
  props: {
    option: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  }
}
</script>
