var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppHeaderDropdown",
    { attrs: { right: "" } },
    [
      _c("template", { slot: "header" }, [
        _vm._v(" " + _vm._s(_vm.$t("shared.navbar.region")) + " "),
        _c("strong", [_vm._v(_vm._s(_vm.getRegion(_vm.region)))]),
      ]),
      _c(
        "template",
        { slot: "dropdown" },
        _vm._l(_vm.regions, function (item) {
          return _c(
            "b-dropdown-item",
            {
              key: item.value,
              attrs: { title: item.text },
              on: {
                click: function ($event) {
                  return _vm.setRegion(item.value)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.text) + " ")]
          )
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }