<template>
  <header-search-bar
    :is-loading="isLoading"
    :is-match="isMatch"
    :options="options"
    @select="goToSelected"
    @search-change="searchByWord"
    @close="resetData"
  />
</template>
<script>
import HeaderSearchBar from '@components/search/common/search-bar';
import Formatters from '@utils/formatters';
import GlobalSearchQueryQL from '@graphql/search/globalSearch.graphql';
import { debounce } from '@utils/debounce';
import LocalSearchMixin from './mixins/local-search';
import DataManipulatorMixin from './mixins/data-manipulator';
import {
  VEHICLE_TYPE,
  DRIVER_TYPE,
  DRIVER_ICON,
  VEHICLE_ICON,
} from './enums';

const getBaseData = () => ({
  match: false,
  drivers: [],
  cars: [],
});

export default {
  name: 'HeaderSearch',
  mixins: [
    LocalSearchMixin,
    DataManipulatorMixin,
  ],
  components: {
    HeaderSearchBar,
  },
  data: () => ({
    loading: false,
    search: getBaseData(),
  }),
  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      cache: false,
    },
    isMatch: {
      get() {
        const { match } = { ...this.search };
        return match;
      },
      cache: false,
    },
    driversResult: {
      get() {
        const { drivers } = { ...this.search };

        const filteredDrivers = drivers.map((d) => ({
          ...d,
          type: DRIVER_TYPE,
          icon: DRIVER_ICON,
          title: d.name,
          isActive: !!(d.status === 'ACTIVE'),
          description: this.getDescription(DRIVER_TYPE, d),
          region: typeof d.region === 'string' ? JSON.parse(d.region) : d.region,
        })).filter(Boolean);

        return filteredDrivers.length
          ? [{
            category: this.$t('component.headerSearch.categoryOptions.drivers'),
            data: filteredDrivers,
          }]
          : [];
      },
      cache: false,
    },
    carsResult: {
      get() {
        const { cars } = { ...this.search };

        const filteredCars = cars.map((c) => ({
          ...c,
          model: Formatters.clearBrandName(c.model),
          isActive: !!(c.status === 'ACTIVE'),
          hasBooking: !!(c.booking?.status === 'ACTIVE'),
          type: VEHICLE_TYPE,
          title: Formatters.formatCarPlate(c.license_plate),
          icon: VEHICLE_ICON,
          description: this.getDescription(VEHICLE_TYPE, c),
        })).filter(Boolean);

        return filteredCars.length
          ? [{
            category: this.$t('component.headerSearch.categoryOptions.cars'),
            data: filteredCars,
          }]
          : [];
      },
      cache: false,
    },
    options: {
      get() {
        return [
          ...this.driversResult,
          ...this.carsResult,
          this.localSearchItems,
        ].filter(Boolean)
      },
      cache: false,
    },
  },
  methods: {
    resetData() {
      this.search = getBaseData();
    },
    async goToSelected(selectedOption) {
      try {
        this.saveLocalSearchItem(selectedOption);

        const getRoutePath = (id) => {
          if(selectedOption.type === VEHICLE_TYPE) return `/fleet/${id}`;
          if(selectedOption.type === DRIVER_TYPE) return `/drivers/profile/${id}`;
        }

        if(this.$route.params.id !== selectedOption.id){
          await this.$router.push({
            path: getRoutePath(selectedOption.id),
          });
        } else {
          this.$swal({
            type: 'warning',
            title: this.$t('component.headerSearch.warning.hey'),
            text: this.$t('component.headerSearch.warning.sameRoute'),
            showConfirmButton: true,
          });
        }

        this.resetData();
      } catch (e) {
        this.$swal({
          type: 'error',
          title: this.$t('component.headerSearch.error.ops'),
          text: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    searchByWord: debounce(async function (word){
      if (word.length <= 3) return false;

      try {
        this.loading = true;
        const { data } = await this.$apollo.query({
          query: GlobalSearchQueryQL,
          fetchPolicy: 'no-cache',
          variables: {
            word: `${word}`,
          },
        });

        if (data.search) {
          this.search = data.search;
        }
      } catch (e) {
        this.$swal({
          type: 'error',
          title: 'Ocorreu um erro!',
          text: e.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } finally {
        this.loading = false;
      }
    }, 350),
  },
}
</script>
