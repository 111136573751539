<template>
  <b-navbar-nav class="d-md-down-none ml-auto mr-4">
    <b-nav-item
      target="_blank"
      class="support"
      href="https://support.kovi.us/?utm_source=rental"
    >
      {{ $t('shared.navbar.support') }}
    </b-nav-item>
    <b-nav-item target="_blank" class="support" href="https://erp.kovi.us/?utm_source=rental">
      ERP
    </b-nav-item>
    <user-header-region />
    <b-nav-item class="notifications">
      <AppHeaderDropdown right no-caret>
        <template slot="header">
          <i class="fa fa-bell-o" />
          <b-badge v-if="userNotifications > 0" pill variant="danger">
            {{ userNotifications }}
          </b-badge>
        </template>
        <template slot="dropdown">
          <b-dropdown-item disabled>
            {{ $t('shared.navbar.zeroNotifications') }}
          </b-dropdown-item>
        </template>
      </AppHeaderDropdown>
    </b-nav-item>
    <b-nav-item class="profile">
      <AppHeaderDropdown right no-caret>
        <template slot="header">
          <user-photo :email="userEmail" />
        </template>
        <template slot="dropdown">
          <!-- <b-dropdown-item>
            <i class="fa fa-user" /> Conta
          </b-dropdown-item>-->
          <!-- <b-dropdown-item><i class="fa fa-shield" /> Bloquear tela</b-dropdown-item> -->
          <b-dropdown-item @click="logout">
            <i class="fa fa-sign-out" /> Sair
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item v-if="build" :href="buildUrl">
            <i class="fa fa-rebel" />Rental
            <strong>v{{ build }}</strong>
            ({{ env }})
          </b-dropdown-item>
          <b-dropdown-item v-else disabled>
            <i
              class="fa"
              :class="env === 'local' ? 'fa-empire' : 'fa-rebel'"
            />
            Rental
            <strong>{{ env }}</strong> version
          </b-dropdown-item>
        </template>
      </AppHeaderDropdown>
    </b-nav-item>
  </b-navbar-nav>
</template>

<style lang="scss" scoped>
  .app-header > .nav-item {
    margin-left: 15px;
    line-height: 35px;

    &.profile {
      margin-left: 5px;
    }
    &.notifications {
      margin-left: 0;
    }
  }
  #profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
</style>

<script>
  import UserHeaderRegion from '@components/user-header-region';
  import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
  import UserPhoto from '@components/user-photo';
  import { signOut } from '@views/auth/commons/hostedUi';

  export default {
    name: 'UserHeader',
    components: {
      AppHeaderDropdown,
      UserPhoto,
      UserHeaderRegion,
    },
    props: {
      version: {
        type: String,
        default: '',
      },
      env: {
        type: String,
        default: 'local',
      },
      buildUrl: {
        type: String,
        default: '',
      },
      build: {
        type: String,
        default: '',
      },
      userEmail: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        userNotifications: 0,
      };
    },
    methods: {
      async logout() {
        await signOut();
      },
    },
  };
</script>
