<template>
  <!-- Contact Driver Modal -->
  <b-modal id="modalContact" ref="modalContact" lazy
           no-fade size="lg" :title="data.modal.title"
           :ok-title="data.modal.save_button" cancel-title="Cancelar" @show="handleOpen"
           @ok="handleSubmit"
  >
    <b-form validate>
      <b-form-row>
        <b-form-group id="id_email_body" label="Corpo da mensagem (Adicionamos uma saudação e uma assinatura)" label-for="email_body"
                      trim="true" class="col-md-12 col-sm-12"
        >
          <b-form-textarea id="id_email_body" v-model="data.message" :disabled="this.block_ui"
                           type="text" rows="5" trim="true"
          />
        </b-form-group>
      </b-form-row>
      <b-alert show variant="warning"><strong>ATENÇÃO</strong> Esta mensagem será enviada para o motorista via Email e SMS.</b-alert>
    </b-form>
  </b-modal>
</template>

<script>
import CONTACT_DRIVER from '@graphql/driver/mutations/send-message.gql';

export default {
  name: 'ContactDriver',
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        id: null,
        sms_message: '',
        email_subject: '',
        email_message: '',
        modal: {
          title: 'Nova mensagem para o motorista',
          title_success: 'Mensagem enviada!',
          save_button: 'Enviar',
          title_error: 'Ocorreu um erro!',
          message_error: 'Verifique os campos digitados novamente',
        },
      },
      block_ui: false,
    };
  },
  methods: {
    handleOpen() {
      this.block_ui = false;
      this.data.message = '';
    },
    async sendMessage() {
      const original_data = this.data;
      this.block_ui = true;
      if (!this.data.message) {
        this.$swal({
          type: 'error',
          title: this.data.modal.title_error,
          text: this.data.modal.message_error,
          showConfirmButton: false,
          timer: 2500,
        }).then(() => {
          this.block_ui = false;
        });
      } else {
        await this.$apollo
          .mutate({
            mutation: CONTACT_DRIVER,
            variables: {
              id: this.modal_data.id,
              message: this.data.message,
            },
          })
          .then(() => {
            this.$swal({
              type: 'success',
              title: this.data.modal.title_success,
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              this.$root.$emit('bv::hide::modal', 'modalContact');
            });
          })
          .catch(() => {
            this.data = original_data;
            this.$swal({
              type: 'error',
              title: this.data.modal.title_error,
              text: this.data.modal.message_error,
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              this.block_ui = false;
            });
          });
      }
    },

    async handleSubmit(e) {
      e.preventDefault();
      await this.sendMessage();
    },
  },
};
</script>
