var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "AppHeader",
        { attrs: { fixed: "" } },
        [
          _c(
            "i",
            {
              staticClass: "menu-toggler",
              class: { opened: _vm.menuView },
              on: {
                click: function ($event) {
                  _vm.menuView = !_vm.menuView
                },
              },
            },
            [
              _c("div", { staticClass: "dashs" }, [
                _c("span"),
                _c("span"),
                _c("span"),
              ]),
            ]
          ),
          _c("b-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "navbar-brand-full",
              attrs: {
                src: "/img/brand/logo.svg",
                width: "130",
                height: "30",
                alt: "Kovi Rental",
              },
            }),
            _c("img", {
              staticClass: "navbar-brand-minimized",
              attrs: {
                src: "/img/brand/logo-square.svg",
                width: "30",
                height: "30",
                alt: "Kovi Rental",
              },
            }),
          ]),
          _c(
            "b-nav-form",
            { staticClass: "d-md-down-none d-inline flex-fill px-2" },
            [_c("header-search")],
            1
          ),
          _c("user-header", {
            attrs: {
              version: _vm.pkgVersion,
              env: _vm.appEnv,
              "build-url": _vm.appBuildUrl,
              build: _vm.appBuild,
              "user-email": _vm.userEmail,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-body", class: { "no-menu": !_vm.menuView } },
        [
          _c("v-side-menu"),
          _c("main", { staticClass: "main" }, [
            _c(
              "div",
              { staticClass: "container-fluid top-space" },
              [_vm._t("navigation"), _vm._t("content"), _vm._t("default")],
              2
            ),
          ]),
        ],
        1
      ),
      _c("modal-inspection-car-schedule"),
      _c("modal-delivery-car-schedule"),
      _c("modal-devolution-car-schedule"),
      _c("modal-delivery-and-devolution-car-schedule"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }