import $i18n from 'i18next';
import { COUNTRIES } from '@src/enums/regions';

export const availableTypeDeliverySchedule = [
  {
    value: 'REBOOKING',
    text: $i18n.t('drivers.forms.rebooking.text'),
    description: $i18n.t('drivers.forms.rebooking.description'),
  },
  {
    value: 'EXCHANGE_MAINTENANCE',
    text: $i18n.t('drivers.forms.exchangeMaintenance.text'),
    description: $i18n.t('drivers.forms.exchangeMaintenance.description'),
  },
  {
    value: 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR',
    text: $i18n.t('drivers.forms.exchangeMaintenanceOriginalCar.text'),
    description: $i18n.t('drivers.forms.exchangeMaintenanceOriginalCar.description'),

  },
  {
    value: 'EXCHANGE_SINISTER',
    text: $i18n.t('drivers.forms.exchangeSinister.text'),
    description: $i18n.t('drivers.forms.exchangeSinister.description'),
  },
  {
    value: 'EXCHANGE_PLAN_AND_CAR',
    text: $i18n.t('drivers.forms.exchangePlanAndCar.text'),
    description: $i18n.t('drivers.forms.exchangePlanAndCar.description'),
  },
  {
    value: 'EXCHANGE_PLAN',
    text: $i18n.t('drivers.forms.exchangePlan.text'),
    description: $i18n.t('drivers.forms.exchangePlan.description'),
  },
  {
    value: 'EXCHANGE_TOKEN',
    text: $i18n.t('drivers.forms.exchangeToken.text'),
    description: $i18n.t('drivers.forms.exchangeToken.description'),
  },
  {
    value: 'UPDATE_CONTRACT',
    text: $i18n.t('drivers.forms.updateContract.text'),
    description: $i18n.t('drivers.forms.updateContract.description'),
  },
  {
    value: 'CAR_EXCHANGE_SAFETY',
    text: $i18n.t('drivers.forms.carExchangeSafety.text'),
    description: $i18n.t('drivers.forms.carExchangeSafety.description'),
  },
  {
    value: 'EXCHANGE_APREHENSION',
    text: $i18n.t('drivers.forms.exchangeAprehension.text'),
    description: $i18n.t('drivers.forms.exchangeAprehension.description'),
  },
  {
    value: 'EXCHANGE_DEMOBILIZATION',
    text: $i18n.t('drivers.forms.exchangeDemobilization.text'),
    description: $i18n.t('drivers.forms.exchangeDemobilization.description'),
  },
  {
    value: 'EXCHANGE_OTHERS',
    text: $i18n.t('drivers.forms.exchangeOthers.text'),
    description: $i18n.t('drivers.forms.exchangeOthers.description'),
  }
]

export const availableTypeDevolutionSchedule = [
  {
    value: 'DEVOLUTION_VOLUNTARY',
    text: $i18n.t('drivers.forms.devolutionVoluntary.text'),
    description: $i18n.t('drivers.forms.devolutionVoluntary.description'),
  },
  {
    value: 'DEVOLUTION_DEFAULT',
    text: $i18n.t('drivers.forms.devolutionDefault.text'),
    description: $i18n.t('drivers.forms.devolutionDefault.description'),
  },
  {
    value: 'DEVOLUTION_MAINTENANCE',
    text: $i18n.t('drivers.forms.devolutionMaintenance.text'),
    description: $i18n.t('drivers.forms.devolutionMaintenance.description'),
  },
  {
    value: 'COLLISION',
    text: $i18n.t('drivers.forms.collision.text'),
    description: $i18n.t('drivers.forms.collision.description'),
  },
  {
    value: 'COLLISION_THIRD_PARTY',
    text: $i18n.t('drivers.forms.collisionThirdParty.text'),
    description: $i18n.t('drivers.forms.collisionThirdParty.description'),
  },
  {
    value: 'ROBBERY_THEFT',
    text: $i18n.t('drivers.forms.robberyTheft.text'),
    description: $i18n.t('drivers.forms.robberyTheft.description'),
  },
  {
    value: 'ROBBERY_THEFT_ATTEMPTED',
    text: $i18n.t('drivers.forms.robberyTheftAttempted.text'),
    description: $i18n.t('drivers.forms.robberyTheftAttempted.description'),
  },
  {
    value: 'COLLECTION_BREACH_CONTRACT',
    text: $i18n.t('drivers.forms.collectionBreachContract.text'),
    description: $i18n.t('drivers.forms.collectionBreachContract.description'),
  },
  {
    value: 'COLLECTION_FRAUD_ANALYSIS',
    text: $i18n.t('drivers.forms.collectionFraudeAnalysis.text'),
    description: $i18n.t('drivers.forms.collectionFraudeAnalysis.description'),
  },
  {
    value: 'COLLECTION_CONFIRMED_FRAUD',
    text: $i18n.t('drivers.forms.collectionConfirmedFraud.text'),
    description: $i18n.t('drivers.forms.collectionConfirmedFraud.description'),
  },
  {
    value: 'DEVOLUTION_FINES_POINTS',
    text: $i18n.t('drivers.forms.devolutionFinesPoints.text'),
    description: $i18n.t('drivers.forms.devolutionFinesPoints.description'),
  },
  {
    value: 'DEVOLUTION_APREHENSION',
    text: $i18n.t('drivers.forms.devolutionAprehension.text'),
    description: $i18n.t('drivers.forms.devolutionAprehension.description'),
  },
  {
    value: 'CAR_WITHOUT_LICENSE_PLATE',
    text: $i18n.t('drivers.forms.carWithoutLicensePlate.text'),
    description: $i18n.t('drivers.forms.carWithoutLicensePlate.description'),
  },
  {
    value: 'DEMOBILIZATION',
    text: $i18n.t('drivers.forms.demobilization.text'),
    description: $i18n.t('drivers.forms.demobilization.description'),
  },
  {
    value: 'COLLECTION_SAFETY',
    text: $i18n.t('drivers.forms.collectionSafety.text'),
    description: $i18n.t('drivers.forms.collectionSafety.description'),
  },
  {
    value: 'APPROPRIATION',
    text: $i18n.t('drivers.forms.appropriation.text'),
    description: $i18n.t('drivers.forms.appropriation.description'),
  },
  {
    value: 'NATURAL_EVENTS',
    text: $i18n.t('drivers.forms.naturalEvents.text'),
    description: $i18n.t('drivers.forms.naturalEvents.description'),
  },
  {
    value: 'DEVOLUTION_OTHERS',
    text: $i18n.t('drivers.forms.devolutionOthers.text'),
    description: $i18n.t('drivers.forms.devolutionOthers.description'),
  },
  {
    value: 'EXCHANGE_MAINTENANCE',
    text: $i18n.t('drivers.forms.exchangeMaintenance.text'),
    description: $i18n.t('drivers.forms.exchangeMaintenance.description'),
  },
  {
    value: 'CAR_EXCHANGE_COLLISION',
    text: $i18n.t('drivers.forms.carExchangeCollision.text'),
    description: $i18n.t('drivers.forms.carExchangeCollision.description'),
  },
  {
    value: 'EXCHANGE_COLLISION_THIRD_PARTY',
    text: $i18n.t('drivers.forms.exchangeCollisionThirdParty.text'),
    description: $i18n.t('drivers.forms.exchangeCollisionThirdParty.description'),
  },
  {
    value: 'CAR_EXCHANGE_SAFETY',
    text: $i18n.t('drivers.forms.carExchangeSafety.text'),
    description: $i18n.t('drivers.forms.carExchangeSafety.description'),
  },
  {
    value: 'EXCHANGE_ROBBERY_THEFT_ATTEMPTED',
    text: $i18n.t('drivers.forms.exchangeRobberyTheftAttempted.text'),
    description: $i18n.t('drivers.forms.exchangeRobberyTheftAttempted.description'),
  },
  {
    value: 'EXCHANGE_NATURAL_EVENTS',
    text: $i18n.t('drivers.forms.exchangeNaturalEvents.text'),
    description: $i18n.t('drivers.forms.exchangeNaturalEvents.description'),
  },
  {
    value: 'CAR_EXCHANGE_ROBBERY_THEFT',
    text: $i18n.t('drivers.forms.carExchangeRobberyTheft.text'),
    description: $i18n.t('drivers.forms.carExchangeRobberyTheft.description'),
  },
  {
    value: 'EXCHANGE_LICENSE_PLATE',
    text: $i18n.t('drivers.forms.exchangeLicensePlate.text'),
    description: $i18n.t('drivers.forms.exchangeLicensePlate.description'),
  },
  {
    value: 'EXCHANGE_DEMOBILIZATION',
    text: $i18n.t('drivers.forms.exchangeDemobilization.text'),
    description: $i18n.t('drivers.forms.exchangeDemobilization.description'),
  },
  {
    value: 'EXCHANGE_APREHENSION',
    text: $i18n.t('drivers.forms.exchangeAprehension.text'),
    description: $i18n.t('drivers.forms.exchangeAprehension.description'),
  },
  {
    value: 'INSURANCE_DEVOLUTION',
    text: $i18n.t('drivers.forms.insuranceDevolution.text'),
    description: $i18n.t('drivers.forms.insuranceDevolution.description'),
  },
  {
    value: 'INSURANCE_TRACKER_MAINTENANCE',
    text: $i18n.t('drivers.forms.insuranceTrackerMaintenance.text'),
    description: $i18n.t('drivers.forms.insuranceTrackerMaintenance.description'),
  },
  {
    value: 'EXCHANGE_PLAN_AND_CAR',
    text: $i18n.t('drivers.forms.exchangePlanAndCar.text'),
    description: $i18n.t('drivers.forms.exchangePlanAndCar.description'),
  }
]

export const availableTypeDevolutionAndDeliverySchedule = [
  {
    value: 'EXCHANGE_MAINTENANCE',
    text: $i18n.t('drivers.forms.exchangeMaintenance.text'),
    description: $i18n.t('drivers.forms.exchangeMaintenance.description'),
  },
  {
    value: 'EXCHANGE_MAINTENANCE_ORIGINAL_CAR',
    text: $i18n.t('drivers.forms.exchangeMaintenanceOriginalCar.text'),
    description: $i18n.t('drivers.forms.exchangeMaintenanceOriginalCar.description'),

  },
  {
    value: 'EXCHANGE_PLAN_AND_CAR',
    text: $i18n.t('drivers.forms.exchangePlanAndCar.text'),
    description: $i18n.t('drivers.forms.exchangePlanAndCar.description'),
  },
  {
    value: 'CAR_EXCHANGE_ROBBERY_THEFT',
    text: $i18n.t('drivers.forms.carExchangeRobberyTheft.text'),
    description: $i18n.t('drivers.forms.carExchangeRobberyTheft.description'),
  },
  {
    value: 'EXCHANGE_ROBBERY_THEFT_ATTEMPTED',
    text: $i18n.t('drivers.forms.exchangeRobberyTheftAttempted.text'),
    description: $i18n.t('drivers.forms.exchangeRobberyTheftAttempted.description')
  },
  {
    value: 'CAR_EXCHANGE_COLLISION',
    text: $i18n.t('drivers.forms.carExchangeCollision.text'),
    description: $i18n.t('drivers.forms.carExchangeCollision.description')
  },
  {
    value: 'EXCHANGE_COLLISION_THIRD_PARTY',
    text: $i18n.t('drivers.forms.exchangeCollisionThirdParty.text'),
    description: $i18n.t('drivers.forms.exchangeCollisionThirdParty.description')
  },
  {
    value: 'CAR_EXCHANGE_SAFETY',
    text: $i18n.t('drivers.forms.carExchangeSafety.text'),
    description: $i18n.t('drivers.forms.carExchangeSafety.description')
  },
  {
    value: 'EXCHANGE_DEMOBILIZATION',
    text: $i18n.t('drivers.forms.exchangeDemobilization.text'),
    description: $i18n.t('drivers.forms.exchangeDemobilization.description')
  },
  {
    value: 'EXCHANGE_LICENSE_PLATE',
    text: $i18n.t('drivers.forms.exchangeLicensePlate.text'),
    description: $i18n.t('drivers.forms.exchangeLicensePlate.description')
  },
  {
    value: 'EXCHANGE_NATURAL_EVENTS',
    text: $i18n.t('drivers.forms.exchangeNaturalEvents.text'),
    description: $i18n.t('drivers.forms.exchangeNaturalEvents.description')
  },
  {
    value: 'EXCHANGE_APREHENSION',
    text: $i18n.t('drivers.forms.exchangeAprehension.text'),
    description: $i18n.t('drivers.forms.exchangeAprehension.description')
  }
]

export const availableTypeInspectionSchedule = [
  {
    value: 'INSPECTION',
    text: $i18n.t('drivers.forms.inspection.text'),
    description: $i18n.t('drivers.forms.inspection.description'),
    ignoreRegions: []
  },
  {
    value: 'TRACKER_INSPECTION',
    text: $i18n.t('drivers.forms.trackerInspection.text'),
    description: $i18n.t('drivers.forms.trackerInspection.description'),
    ignoreRegions: []
  },
  {
    value: 'ZANZAR_INSPECTION',
    text: $i18n.t('drivers.forms.zanzarInspection.text'),
    description: $i18n.t('drivers.forms.zanzarInspection.description'),
    ignoreRegions: [COUNTRIES.MX]
  },
  {
    value: 'TINSMITHING_SCHEDULED',
    text: $i18n.t('drivers.forms.tinsmithingScheduled.text'),
    description: $i18n.t('drivers.forms.tinsmithingScheduled.description'),
    ignoreRegions: [COUNTRIES.MX]
  },
]
