var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { staticClass: "select-wrapper", attrs: { label: _vm.placeTitle } },
    [
      _c(
        "b-form-select",
        {
          attrs: {
            options: _vm.availablePlaces,
            disabled: !_vm.availablePlaces.length || _vm.disable,
            "data-test": "select-places",
          },
          model: {
            value: _vm.selectedPlace,
            callback: function ($$v) {
              _vm.selectedPlace = $$v
            },
            expression: "selectedPlace",
          },
        },
        [
          _c("template", { slot: "first" }, [
            _c(
              "option",
              { attrs: { disabled: "" }, domProps: { value: null } },
              [_vm._v(" " + _vm._s(_vm.placeText) + " ")]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }