var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { id: "sidebar-menu" } }, [
    _c(
      "ul",
      _vm._l(_vm.menuItems, function (section, index) {
        return _c(
          "li",
          { key: index, staticClass: "section" },
          [
            _vm.verifyIgnoredRegion(section) && _vm.verifyACLAccess(section)
              ? [
                  _c("span", [_vm._v(_vm._s(section.name))]),
                  _c(
                    "ul",
                    _vm._l(section.subitems, function (item, indexSub) {
                      return _c(
                        "li",
                        {
                          key: indexSub,
                          staticClass: "items",
                          class: { active: "router-link-active" },
                          attrs: { title: item.name },
                        },
                        [
                          _vm.verifyIgnoredRegion(item) &&
                          _vm.verifyACLAccess(item)
                            ? [
                                item.event === undefined
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "like-link",
                                        attrs: {
                                          to: item.url,
                                          title: item.name,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "like-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMenuAction(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }