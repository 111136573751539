var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalContact",
      attrs: {
        id: "modalContact",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.data.modal.title,
        "ok-title": _vm.data.modal.save_button,
        "cancel-title": "Cancelar",
      },
      on: { show: _vm.handleOpen, ok: _vm.handleSubmit },
    },
    [
      _c(
        "b-form",
        { attrs: { validate: "" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-12 col-sm-12",
                  attrs: {
                    id: "id_email_body",
                    label:
                      "Corpo da mensagem (Adicionamos uma saudação e uma assinatura)",
                    "label-for": "email_body",
                    trim: "true",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "id_email_body",
                      disabled: this.block_ui,
                      type: "text",
                      rows: "5",
                      trim: "true",
                    },
                    model: {
                      value: _vm.data.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "message", $$v)
                      },
                      expression: "data.message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _c("strong", [_vm._v("ATENÇÃO")]),
            _vm._v(
              " Esta mensagem será enviada para o motorista via Email e SMS."
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }